<template>
  <div class="flow-chart-container" :style="{'font-size': fontSize + 'px'}">
    <m-ratio-box class="ratio-box" :ratio="87">
      <div class="control">
        <!-- Menu只适用流量排行和预算排行 -->
        <Menu title="流量排行" :type="type" @changeMenu="changeMenu" />
        <div class="date-picker-wrapper">
          <a-range-picker
            class="date-picker"
            format="MM.DD"
            :placeholder="['Start', 'End']"
            v-model="date"
            :allowClear="false"
            :disabledDate="disabledDate"
            @change="changeDate"
            size="small"
            ref="datepicker"
            :ranges="{
              今日: [initDate(0, 0).start, initDate(0, 0).end],
              昨日: [initDate(1, 1).start, initDate(1, 1).end],
              最近一周: [initDate(6, 0).start, initDate(6, 0).end],
              最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
            }"
            :align="{
              offset: ['-50px', '0px']
            }"
          >
            <a-icon slot="suffixIcon" type="down" class="suffixIcon" />
          </a-range-picker>
          <a-select
            class="search_item"
            v-model="currentType"
            @change="changeTypes"
          >
            <a-select-option
              v-for="(child, index) in typeList"
              :key="index"
              :value="child.id"
            >
              {{ child.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="flow-chart-content">
        <Charts
          v-if="listType === 0"
          v-bind="{
            ...$attrs,
            query: { dateStart, dateEnd, code: currentType }
          }"
        />
        <listDisplay
          v-if="listType === 1"
          :type="1"
          v-bind="{
            ...$attrs,
            formquery: { dateStart, dateEnd, code: currentType }
          }"
        />
      </div>
    </m-ratio-box>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
import { getDurations } from '@/utils/duration'
import listDisplay from '../../components/listDisplay'
import Menu from '../../components/menu'
import Charts from './chart.vue'
import { typeList } from './list'
import autoFontSize from '@/mixins/autoFontSize'

export default {
  mixins: [mixDate, autoFontSize],
  components: { listDisplay, Charts, Menu },
  data () {
    return {
      spinning: false,
      date: [],
      query: {
        code: 'request',
        dateEnd: '',
        dateStart: ''
      },
      listType: 0,
      type: 0, // 0图表 1列表
      listData: [],
      option: {},
      colorList: ['#3de4a9', '#3b78ff', '#7451de', '#bd58f7', '#77a5eb'],
      myEcharts: null,
      currentType: 'request',
      currentName: '流量请求',
      hasData: true,
      dateStart: '',
      dateEnd: '',
      typeList,
      visible: false
    }
  },
  props: {
    modelType: {
      default: 0,
      type: Number
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      moneyType: (state) => +state.user.cur === 1 ? '$' : '¥'
    })
  },
  created () {
    const { start, end, endDate, startDate } = this.initDate(1, 1)
    this.dateStart = startDate
    this.dateEnd = endDate
    this.date = [start, end]
    this.changeTypes()
  },
  mounted () {},
  methods: {
    changeMenu (data) {
      this.listType = data
    },
    changeTypes () {
      this.currentName = this.typeList.find((item) => item.id === this.currentType).name
    },
    changeDate (date) {
      this.dateStart = date[0].format('YYYY-MM-DD')
      this.dateEnd = date[1].format('YYYY-MM-DD')
      this.date = date
    },
    // 不可选时间
    disabledDate (current) {
      return (current && current < this.initDate(getDurations(), 1).start) || current >= this.initDate(0, 0).end
    },
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        setTimeout(() => {
          this.myEcharts.resize()
        }, 100)
      }
    },
    initChart () {
      this.myEcharts.setOption(this.option)
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';

::v-deep.flow-chart-container {
  .ant-calendar-picker-input.ant-input {
    padding-left: 0!important;
  }
  .ant-calendar-range-picker-separator {
    vertical-align: baseline;
  }
  .ant-calendar-range-picker-input {
    width: 40%;
  }
  .ant-calendar-picker .ant-calendar-picker-input.ant-input.ant-input-sm {
    height: 36px!important;
  }
}
.flow-chart-container {
  .aRadioBtn();
  width: calc(32.8% - 12em / 14);
  border-radius: @mediumRadius;
  padding: calc(24em / 14);
  background: #FFFFFF;
  box-shadow: @modelShadow;
  border: 1px solid #D9E1EC;
  .control {
    display: flex;
    justify-content: space-between;
    padding-left: calc(6em / 14);
    .icon-down {
      margin-left: calc(5em / 14);
    }
    .date-picker-wrapper {
      width: 73%;
      display: flex;
      justify-content: flex-end;
      margin-top: calc(-8em / 14);
      .date-picker {
        margin-right: calc(16em / 14);
        width: calc(160em / 14);
      }
      .suffixIcon {
        font-size: calc(12em / 14);
      }
    }
  }
  .search_item {
    width: calc(120em / 14);
  }
  .flow-chart-content {
    height: 100%;
    position: relative;
  }
  .empty{
    width: 100%;
    height: 100%;
    background-image: url('/images/data-empty.png');
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
